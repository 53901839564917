.navbar-brand {
  padding-top: 0;
}
a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}
.navbar {
  background-position: center;
  background-color: dimgray;
  height: 50px;
}

.navbar-light .navbar-nav .nav-link {
  color:aliceblue;
}

a.nav-link {
  color: white;
}