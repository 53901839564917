/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary, .ant-btn-primary {
  color: #fff;
  background-color: dimgray;
  border-color: dimgray;
}

.gLMJsD {
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
}



.kaqksj {
    height: 30px;
}
*, ::before, ::after {
    box-sizing: border-box;
}

.lbVyER {
    min-width: 0px;
    color: rgb(199, 5, 31);
    text-decoration: underline;
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button,
span.anticon {
  display: inline-flex;
  align-items: center;
}

.ant-menu-light .ant-menu-item-selected {
  background-color: lightgray;
}

.nowrap {
  white-space: nowrap;
}